import { Component } from '@angular/core';

@Component({
  selector: 'app-acr-card',
  templateUrl: './acr-card.component.html',
  styleUrls: ['./acr-card.component.css']
})
export class AcrCardComponent {

}
