<section>
    <div class="container">
        <div class="row">
            <div class="col-lg- col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6">
                    <div class="card-header">
                        <h4><strong>Manchester  </strong></h4>
                        <div class="row">
                            <div class="col-6">
                                <p>Contract Start Date: <br><strong>01 Sept 2024</strong></p>
                            </div>
                            <div class="col-6 text-end">
                                <p>Length of Contract: <br><strong>4-7 Years</strong></p>
                            </div>
                            <div class="col-12">
                                <p>Number of resources: <strong>45+</strong></p>
                            </div>

                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <p class="text-muted"><strong>Client location(s)</strong></p>
                                <p>Manchester M13</p>
                            </div>
                            <div class="col-12">
                                <p class="text-muted"><strong>Work secondary location</strong></p>
                                <p>Swindon (SN1)</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <p class="text-muted"><strong>Working Pattern</strong></p>
                                <p>Mostly flexible but will be discussed for each role, when it became active</p>
                            </div>
                            <div class="col-12 mb-2">
                                <p class="text-muted"><strong>IR 35</strong></p>
                                <p>Outside</p>
                            </div>
                            <div class="col-12">
                                <p class="text-muted"><strong>Security Clearance</strong></p>
                                <p>Basic clearance BUT NO SC/DV</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <p class="text-muted"><strong>Roles in Demand</strong></p>
                                <p>Functional Test Analyst</p>
                                <p>System Integration Test Analyts</p>
                                <p>Performance Test Analyst</p>
                                <p>End to End Test Manager/Programme Test Manager/Test Delivery Manager</p>
                                <p> Programme Test Manager</p>
                                <p> Accessibility Tester</p>
                                <p> Data Migration Tester</p>
                                <p> UAT Test Analyst</p>
                                <p> Functional Test Lead</p>
                                <p> UAT Test Lead</p>
                                <p> Performance Test Lead</p>
                                <p> Technical Test Analyst</p>
                                <p> Defect Manager</p>
                                <p> Test Automation Engineer</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg- col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6">
                    <div class="card-header">
                        <h4><strong>Northern Ireland  </strong></h4>
                        <div class="row">
                            <div class="col-6">
                                <p>Contract Start Date: <br><strong>01 Apr 2025</strong></p>
                            </div>
                            <div class="col-6 text-end">
                                <p>Length of Contract: <br><strong>5 years</strong></p>
                            </div>
                            <div class="col-12">
                                <p>Number of resources: <strong>40+</strong></p>
                            </div>

                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <p class="text-muted"><strong>Client location(s)</strong></p>
                                <p>Northen Ireland</p>
                            </div>
                            <div class="col-12">
                                <p class="text-muted"><strong>Work secondary location</strong></p>
                                <p>Swindon (SN1)</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <p class="text-muted"><strong>Working Pattern</strong></p>
                                <p>Mostly flexible but will be discussed for each role, when it became active</p>
                            </div>
                            <div class="col-12 mb-2">
                                <p class="text-muted"><strong>IR 35</strong></p>
                                <p>Outside</p>
                            </div>
                            <div class="col-12">
                                <p class="text-muted"><strong>Security Clearance</strong></p>
                                <p>Basic clearance BUT NO SC/DV</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <p class="text-muted"><strong>Roles in Demand</strong></p>
                                <p> QA / Test Architect</p>
                                <p> QA / Test Manager</p>
                                <p> QA/Functional Test Lead</p>
                                <p> QA / Test Analyst</p>
                                <p> Non-Functional Test (NFT) Lead</p>
                                <p> Non-Functional Test (NFT) Analyst</p>
                                <p> Automation Analyst/Engineer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg- col-xl-4 col-md-6 col-sm-6 col-12 mb-4">
                <div class="FlatCard shadow border-radius6">
                    <div class="card-header">
                        <h4><strong>Public Sector POOL  </strong></h4>
                        <div class="row">
                            <div class="col-6">
                                <p>Contract Start Date: <br><strong>01 sep 2024</strong></p>
                            </div>
                            <div class="col-6 text-end">
                                <p>Length of Contract: <br><strong>Ongoing </strong></p>
                            </div>
                            <div class="col-12">
                                <p>Number of resources: <strong>150+</strong></p>
                            </div>

                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <p class="text-muted"><strong>Client location(s)</strong></p>
                                <p>East Midlands</p>
                                <p> East of England</p>
                                <p> Greater London</p>
                                <p> Northeast England</p>
                                <p> Northwest England</p>
                                <p> Southeast England</p>
                                <p> Southwest England West Midlands</p>

                            </div>
                            <div class="col-12">
                                <p class="text-muted"><strong>Work secondary location</strong></p>
                                <p>Swindon (SN1)</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <p class="text-muted"><strong>Working Pattern</strong></p>
                                <p>Mostly flexible but will be discussed for each role, when it became active</p>
                            </div>
                            <div class="col-12 mb-2">
                                <p class="text-muted"><strong>IR 35</strong></p>
                                <p>Mix of Inside & Outside</p>
                            </div>
                            <div class="col-12">
                                <p class="text-muted"><strong>Security Clearance</strong></p>
                                <p>Senior roles demand existing SC/DV (or should have eligibility)</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <p class="text-muted"><strong>Roles in Demand</strong></p>
                                <p> Quality Assurance Testing (QAT) Specialists</p>
                                <p> Quality Assurance (QA) & DevOps</p>
                                <p> Load & Performance Testing</p>
                                <p> Quality Assurance (QA) & Testing</p>
                                <p> Infrastructure Testing</p>
                                <p> Operational Acceptance Testing (OAT)</p>
                                <p> Strategic Quality Assurance Consultant (Senior)</p>
                                <p> Accessibility Quality Assurance (QA) and Testing</p>
                                <p> Security Quality Assurance (QA) and Testing</p>
                                <p> Quality Assurance (QA) Capability Development</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>