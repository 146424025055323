<section>
  <div>
    <div class="table-responsive">
      <div class="caption" style="width: 100%;">
        <table class=" caption-top caption" style="width: 100%;">
          <thead >
            <tr>
              <th class="cellcolor">Client Name</th>
              <th class="cellcolor">Contract Start date</th>
              <th class="cellcolor">Length of contract</th>
              <th class="cellcolor">Number of resources</th>
              <th class="cellcolor">Client location(s)</th>
              <th class="cellcolor">Work secondary location</th>
              <th class="cellcolor">Working Pattern</th>
              <th class="cellcolor">IR 35</th>
              <th class="cellcolor">Security Clearance </th>
              <!-- <th>Roles in Demand</th> -->
            </tr>
          </thead>
          <tbody >
            <tr>
              <td class="cellcolor">Manchester based</td>
              <td class="cellcolor">01-09-2024</td>
              <td class="cellcolor">4-7 years</td>
              <td class="cellcolor">45+</td>
              <td class="cellcolor">Manchester M13</td>
              <td class="cellcolor">Swindon (SN1)</td>
              <td class="cellcolor">Mostly flexible but will..</td>
              <td class="cellcolor">Outside </td>
              <td class="cellcolor">Basic clearance BUT NO SC/DV</td>
              <!-- <td></td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <table class="table table-bordered caption-top" style="width: 100%;">
        <thead>
          <tr>
            <th style="width: 32px;"></th>
            <th [width]="100">Roles in Demand</th>
            <th>Role description</th>
            <th>Certifications/Qualifications</th>
            <th>Relevant Experience</th>
            <th [width]="250">Work Location</th>
            <th>Expected Rate</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of manchesterData">
            <tr>
              <td style="width: 32px;">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" style="margin: 0; padding: 0;" [(ngModel)]="item.checked">
              </td>
              <td>{{ item?.roleInDemand }}</td>
              <td>{{ item?.roleDescription }}</td>
              <td>{{ item?.qualification }}</td>
              <td>
                <input type="text" class="form-control" placeholder="Relevant Experience"
                  [(ngModel)]="item.relevantExperience" required>
              </td>
              <td>
                  <select class="form-control" [(ngModel)]="item.workLocation" required>
                    <option value="" disabled selected hidden>Select work location</option>
                    <option value="manchester_only">Manchester Only</option>
                    <option value="blank">Blank</option>
                  </select>
              </td>
              <td>
                <input type="text" class="form-control" placeholder="Expected Rate" [(ngModel)]="item.expectedRate"
                  required>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div style="display: flex; justify-content: end; align-items: center;">
        <div style="width: 106px; margin: 2px;">
          <input type="file" class="form-control" height="50px" width="100px" style="height: 38px;" (change)="fileUpload($event)">
        </div>
        <div style="width: 150px; margin: 2px;">
          <button type="button" class="btn btn-primary btn-block" (click)="submitDetails()">Send Request</button>
        </div>
      </div>
    </div>
  </div>
</section>
