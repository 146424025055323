<div class="section-card">
  <div class="m-3">
    <button class="btn btn-primary" *ngIf="!showFilter" (click)="showFilter = !showFilter">
      Show Filter
    </button>
    <button class="btn btn-primary" *ngIf="showFilter" (click)="showFilter = !showFilter">
      Hide Filter
    </button>
    <button class="btn btn-primary" style="float: right;" routerLink="/database/list">
      Back
    </button>
  </div>

  <!-- Start User Filter -->
  <ng-container *ngIf="pageType == 'User'">
    <div class="row m-3" *ngIf="showFilter">
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Name" [(ngModel)]="userFilter.name" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Phone Number" (keypress)="NumberOnly($event)"
            [(ngModel)]="userFilter.phoneNumber" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Email" [(ngModel)]="userFilter.email" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Country" [(ngModel)]="userFilter.country" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="userFilter.UKDrivinglicense">
            <option value="" disabled selected hidden>Uk Driving License</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="userFilter.nationality">
            <option value="" disabled selected hidden>Select Nationality</option>
            <option value="british">British</option>
            <option value="other">Others</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="userFilter.currentWork">
            <option value="" disabled selected hidden>Current Working As</option>
            <option value="full-time">Full Time</option>
            <option value="part-time">Part Time</option>
            <option value="not-working">Not working</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <button type="button" class="btn btn-primary" (click)="getTableDetails()">Submit</button>
        <button type="button" class="btn btn-primary" (click)="resetFilter()">Reset</button>
      </div>
    </div>
  </ng-container>

  <!-- ACR Filter -->
  <ng-container *ngIf="pageType == 'card'">
    <div class="row m-3" *ngIf="showFilter">
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Role Demand" [(ngModel)]="cardFilter.rolesInDemand" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Role Description"
            [(ngModel)]="cardFilter.roleDescription" />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <select class="form-control" [(ngModel)]="cardFilter.type">
            <option value="" disabled selected hidden>Type</option>
            <option value="ACR">ACR</option>
            <option value="CIR">CIR</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Qualifications Certificate"
            [(ngModel)]="cardFilter.certifications_qualifications" />
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Value A" [(ngModel)]="cardFilter.valueA" />
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Value B" [(ngModel)]="cardFilter.valueB" />
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Value C" [(ngModel)]="cardFilter.valueC" />
        </div>
      </div>

      <div class="col-4">
        <button type="button" class="btn btn-primary" (click)="getTableDetails()">Submit</button>
        <button type="button" class="btn btn-primary" (click)="resetFilter()">Reset</button>
      </div>
    </div>
  </ng-container>

  <!-- Start Database Details -->
  <div class="FlatCard shadow border-radius6 m-3">
    <div class="row card-header">
      <div class="col-6 text-start d-flex align-items-center">
        <h2 class="card-title">{{ pageType }} Details</h2>
      </div>
      <div class="col-6 text-end">
        <button type="button" class="btn btn-primary" style="padding: 0px 10px !important" (click)="downloadAsExcel()">
          Download Excel
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <ng-container *ngIf="pageType == 'User'">
          <table class="table">
            <thead>
              <th *ngFor="let header of tableHeader">{{ header }}</th>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let item of tableData  |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let index = index">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ item?.name }}</td>
                  <td>{{ item?.email }}</td>
                  <td>{{ item?.countrycode }}</td>
                  <td>{{ item?.phoneNumber }}</td>
                  <td>{{ item?.nationality }}</td>
                  <td>{{ item?.UKDrivinglicense ? 'Yes' : 'No'  }}</td>
                  <td>{{ item?.UKVisaType }}</td>
                  <td>{{ item?.postalCode }}</td>
                  <td>{{ item?.noticePeriodDay }}</td>
                  <td>{{ item?.currentWork }}</td>
                  <td>
                    <ng-container *ngIf="item?.lookingFor?.length > 0">
                      <ul>
                        <li *ngFor="let data of item?.lookingFor">
                          {{ data }}
                        </li>
                      </ul>
                    </ng-container>
                  </td>
                  <td>{{ item?.workLocation }}</td>
                  <td>{{ item?.expectedDayRate }}</td>
                  <td>{{ item?.referredBy }}</td>
                  <td>{{ item?.sc_dv_clearance_hold ? 'Yes' : 'No' }}</td>
                  <td>
                    <button (click)="openDocument(item?.cv)" type="button" class="btn w-100">
                      <i class="fa fa-eye"></i>
                    </button>
                  </td>
                  <td>{{item?.willing_to_undertake}}</td>
                  <td>{{ item?.callDay }}</td>
                  <td>{{ item?.callTime }}</td>
                  <td>
                    <ng-container *ngIf="item?.client1?.roles?.length> 0">
                      <ul>

                        <li *ngFor="let data of item?.client1?.roles">
                          <a ngbTooltip="{{ data?.name }}">
                            <span [innerHTML]="data?.name  | slice:0:30 "></span>
                          </a>
                        </li>

                      </ul>
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="item?.client2?.roles?.length > 0">
                      <ul>
                        <li *ngFor="let data of item?.client2?.roles">
                          <a ngbTooltip="{{ data?.name }}">
                            <span [innerHTML]="data?.name  | slice:0:30 "></span>
                          </a>
                        </li>
                      </ul>
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="item?.client3?.roles?.length > 0">
                      <ul>
                        <li *ngFor="let data of item?.client3?.roles">
                          <a ngbTooltip="{{ data?.name }}">
                            <span [innerHTML]="data?.name  | slice:0:30 "></span>
                          </a>
                        </li>
                      </ul>
                    </ng-container>
                  </td>
                  <td>{{ item?.anyQuestion }}</td>
                  <td>
                    <button (click)="openDocument(item?.profilePicture)" type="button" class="btn w-100">
                      <i class="fa fa-eye"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="pageType == 'ACRUser'">
          <table class="table">
            <thead>
              <th *ngFor="let header of tableHeader">{{ header }}</th>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of tableData; let index = index">
                <tr>
                  <td>{{ item?.agencyName }}</td>
                  <td>{{ item?.location }}</td>
                  <td>{{ item?.numberOfBranchesInUK }}</td>
                  <td>{{ item?.personName }}</td>
                  <td>{{ item?.personDesignation }}</td>
                  <td>{{ item?.userName }}</td>
                  <td>{{ item?.personEmail }}</td>
                  <td>{{ item?.phoneNumber }}</td>
                  <td>{{ item?.contactDetails }}</td>
                  <td>{{ item?.emergencySecondaryContactDetails }}</td>

                  <td>{{ item?.createdAt | date : "dd-MM-YYY" }}</td>
                  <td>{{ item?.updatedAt | date : "dd-MM-YYY" }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="pageType == 'card'">
          <table class="table">
            <thead>
              <th *ngFor="let header of tableHeader">{{ header }}</th>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let item of tableData  |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let index = index">
                <tr>
                  <td>{{ item?.rolesInDemand }}</td>
                  <td>{{ item?.roleDescription }}</td>
                  <td>{{ item?.type }}</td>
                  <td>{{ item?.certifications_qualifications }}</td>
                  <td>{{ item?.valueA }}</td>
                  <td>{{ item?.valueB }}</td>
                  <td>{{ item?.valueC }}</td>
                  <td>
                    <button (click)="openDocument(item?.file)" type="button" class="btn w-100">
                      <i class="fa fa-eye"></i>
                    </button>
                  </td>
                  <td>{{ item?.createdAt | date : "dd-MM-YYY" }}</td>
                  <td>{{ item?.updatedAt | date : "dd-MM-YYY" }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>

      </div>
      <div class="row mt-3 align-items-center">
        <div class="row">
          <div class="pagination">
            <pagination-controls (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>