<section class="section-accordian-card">
  <div class="container">
    <h4 style="text-align: center;"><strong>My Candidate List</strong></h4>
    <div class="table-responsive">
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="text-transform: none; text-align: center;" scope="col">Sr. No.</th>
            <th style="text-transform: none; text-align: center;" scope="col">Candidate Name</th>
            <th style="text-transform: none; text-align: center;" scope="col">Candidate Email</th>
            <th style="text-transform: none; text-align: center;" scope="col">Joining Date</th>
            <th style="text-transform: none; text-align: center;" scope="col">Status</th>
            <!-- <th style="text-transform: none; text-align: center;" scope="col">Any Query?</th> -->
          </tr>
        </thead>
        <tbody>

          <tr>
            <td *ngIf="candidatelist.length == 0" class="text-center" colspan="7">
              No Record Found.
            </td>
          </tr>

          <tr *ngFor="let candidate of candidatelist; let i = index">
            <td>{{i +1}}</td>
            <td>{{candidate.name}}</td>
            <td>{{candidate.email}}</td>
            <td>{{candidate.joiningDate | date:'dd/MM/yyyy'}}</td>
            <td>{{candidate.status}}</td>
            <!-- <td></td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>