import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cir-active-roles',
  templateUrl: './cir-active-roles.component.html',
  styleUrls: ['./cir-active-roles.component.css']
})
export class CirActiveRolesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
