<div>
  <div class="container d-flex justify-content-center align-items-center login-container">
    <div class="row d-flex align-items-center justify-content-center mobile-login-modal"
      style="overflow:hidden; width:100vw; height:100vh;">

      <div class="col-lg-7 col-xl-7 col-md-7 col-sm-7 col-12 text-center p-0">
        <div class="Login-leftside">
          <div class="company-logo">
            <img src="../../../../assets/img/Picture1.png" width="200" class="mb-2" />
            <h3 class="text-white">“Elevate Your Career with SaiVen: Join Public Sector IT Projects”</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-5 col-md-5 col-sm-5 col-12">
        <div class="login-modal">
          <div class="login-body">
            <h2 class="Heading-medium">Reset Password</h2>
            <!-- <p>Enter registerd email to recover your password</p> -->
            <form class="needs-validation" [formGroup]="resetpasswordForm" (ngSubmit)="submit()">

              <div class="row">
                <div class="col-lg-12 colxl-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Password :</label>
                    <div class="input-group mb-0">
                      <input [type]="password" class="form-control" placeholder="Password" formControlName="password"
                        required>
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span *ngIf="!showPassword" class="fas fa-eye" (click)="showHidePass('password')"></span>
                          <span *ngIf="showPassword" class="fas fa-eye-slash" (click)="showHidePass('password')"></span>
                        </div>
                      </div>
                    </div>
                    <span class="text-danger" *ngIf="
                      resetpasswordForm.controls?.['password']?.touched &&
                      resetpasswordForm.controls?.['password']?.errors?.['required']">
                      Please enter password.
                    </span>
                    <span class="text-danger" *ngIf="
                      resetpasswordForm.controls?.['password']?.touched &&
                      resetpasswordForm.controls?.['password']?.errors?.['pattern']">
                      Password must be at least 8 characters long, contain an uppercase letter, a number, and a special
                      character.
                    </span>
                  </div>
                </div>
                <div class="col-lg-12 colxl-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Confirm Password :
                    </label>
                    <div class="input-group mb-0">
                      <input [type]="confirmPassword" class="form-control" placeholder="Confirm Password"
                        formControlName="confirmPassword">
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span *ngIf="!confirmShowPassword" class="fas fa-eye"
                            (click)="showHidePass('confirmPassword')"></span>
                          <span *ngIf="confirmShowPassword" class="fas fa-eye-slash"
                            (click)="showHidePass('confirmPassword')"></span>
                        </div>
                      </div>
                    </div>
                    <span class="text-danger" *ngIf="
                      resetpasswordForm.controls?.['confirmPassword']?.touched &&
                      resetpasswordForm.controls?.['confirmPassword']?.errors?.['required']">
                      Please enter password.
                    </span>
                    <span class="text-danger" *ngIf="
                      resetpasswordForm.controls?.['confirmPassword']?.touched &&
                      resetpasswordForm.controls?.['confirmPassword']?.errors?.['pattern']">
                      Password must be at least 8 characters long, contain an uppercase letter, a number, and a special
                      character.
                    </span>
                  </div>
                </div>
              </div>

              <p>I have remeber my password <a href="javascript:void(0)" routerLink="/cir/cir-login">Back to login !</a>
              </p>
              <!-- <p><a href="javascript:void(0)" routerLink="/cir/cir-forgot-password">Forgot Password?</a></p> -->
              <button type="submit" class="btn btn-primary w-100">Submit</button>
            </form>
          </div>
        </div>
      </div>

    </div> <!-- Inner row -->
  </div> <!-- Outer container -->
</div>
