import { Component } from '@angular/core';

@Component({
  selector: 'app-acr-table',
  templateUrl: './acr-table.component.html',
  styleUrls: ['./acr-table.component.css']
})
export class AcrTableComponent {

}
