import { Component } from '@angular/core';

@Component({
  selector: 'app-cir-card',
  templateUrl: './cir-card.component.html',
  styleUrls: ['./cir-card.component.css']
})
export class CirCardComponent {

}
