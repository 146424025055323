<footer class="footer-main " style="background-color: #007F83 
;">
    <div class="container d-flex justify-content-center" style="padding: 0.75rem;">
        <div class="row justify-content-between">
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 mob-mb1">
              <span>
                1-04, Focal Point, 27-35 Fleet Street, Swindon SN1 1RQ | Company number: 8856876
              </span>
              <!-- <a href="http://www.saivensolutions.co.uk" target="_blank" rel="noopener noreferrer">Website</a> -->
            </div>
        </div>
    </div>
</footer>