<section class="section-accordian-card">
  <section class="section-card">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-7">
          <div class="FlatCard shadow border-radius6 p-0">
            <div class="card-body text-center p-3">
              <img src="./assets/img/coming-soon.jpeg" width="400px" height="400px" class="mt-5 mb-5" />
              <h4 class="mb-5">
                <strong> What Next ?<br> </strong>
              </h4>
              <h5 class="mb-5">
                <strong>
                  Apply for interested Job Titles : We will notify you via email once the roles are live.
                </strong>
              </h5>
              <!-- <div>
                <button type="button" class="btn btn-secondary w-40" routerLink="/cir/cir-refer-earn">Back</button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>