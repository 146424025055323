<!-- <div>
  <header>
    <div class="d-flex justify-content-beween">
      <button>
         profile
      </button>
      <button>
        logout
      </button>
    </div>
  </header>
  <div>
    <router-outlet></router-outlet>
  </div>
</div> -->

<div class="wrapper">
  <div class="main-panel">
    <nav class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
      <div class="container-fluid">
        <div class="navbar-wrapper">
          <div class="navbar-toggle">
            <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <a class="navbar-brand" style="color: white;" href="javascript:void(0)" routerLink="/cir/cir-card">{{getTitle()}}</a>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <!-- [ngbCollapse]="isCollapsed" -->
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link btn-magnify" routerLink="/cir/cir-card">
                <p>Future Roles</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" routerLink="/cir/cir-active-roles">
                <p>Active Roles</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" routerLink="/cir/cir-refer-earn">
                <p>Refer & Earn</p>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link btn-magnify" href="javascript:void(0)">
                <p>{{ loginUser?.name }}</p>
                <p>
                  <span class="d-lg-none d-md-block">{{ loginUser?.name }}</span>
                </p>
              </a>
            </li>

            <!-- Setting button -->
            <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
              <a class="nav-link" ngbDropdownToggle id="navbarDropdownMenuLink" >
                <i class="nc-icon nc-settings-gear-65"></i>
                <p>
                  <span class="d-lg-none d-md-block">Account</span>
                </p>
              </a>
              <div ngbDropdownMenu aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu dropdown-menu-end">
                <a ngbDropdownItem routerLink="/cir/cir-profile">Profile</a>
                <a ngbDropdownItem href="javascript:void(0)" (click)="logout()"> LogOut </a>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </nav>
      <div class="content">
          <router-outlet></router-outlet>
      </div>
  </div>
</div>
