<!-- <div class="modal fade" id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Upload Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="resourcesForm">

          <div class="row">
            <div class="col-md-4">
              <label>How many resources ? <span style="color: red;"> *</span> :</label>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <input type="text" class="form-control" placeholder=" Enter resources" formControlName="howmanyresources">
              </div>
            </div>
          </div>

          <table class="table table-bordered">
            <thead class="card-header">
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Upload CV </th>
                <th scope="col">Candidate Nationality</th>
                <th scope="col">Candidate Location</th>
              </tr>
            </thead>
            <tbody formArrayName="candidates">
              <tr *ngFor="let candidate of candidates.controls; let i = index" >
                <td>{{ i + 1 }}.</td>
                <td>
                  <input type="file" class="form-control" (change)="fileUpload($event)" required>
                </td>
                <td><input type="text" placeholder="Enter nationality" class="form-control" formControlName="nationality" />
                </td>
                <td><input type="text" placeholder="Enter job title" class="form-control"
                    formControlName="location" />
                </td>
                <td>
                  <button type="button" class="btn btn-danger" (click)="removeCandidate(i)"
                    *ngIf="candidates.length > 1">-</button>&nbsp;
                  <button type="button" class="btn btn-primary" (click)="addCandidate()"
                    *ngIf="i === candidates.length - 1">+</button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row">
            <div class="col-md-5">
              <label>Timer:</label>
            </div>
            <div class="col-md-7" style="color: red;">
              <div class="timer" id="timer"><strong>24:00:00</strong></div>
            </div>
          </div>

          <div class="col-3" style="float: right;">
            <div class="d-flex align-items-center justify-content-between">
              <button type="button" (click)="submit()" class="btn btn-primary w-50">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->

<section class="section-accordian-card">
  <div class="container">
    <h4 style="text-align: center;"><strong>All Jobs</strong></h4>
    <div class="table-responsive"><br>
      <form [formGroup]="resourcesForm">

        <div class="row">
          <div class="col-md-4">
            <label>How many resources you can supply in next 24hrs ? <span style="color: red;"> *</span> :</label>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <input type="text" class="form-control" placeholder=" Enter resources" formControlName="howmanyresources">
            </div>
          </div>
        </div>

        <table class="table table-bordered mt-3">
          <thead>
            <tr>
              <th scope="col">Sr. No</th>
              <th scope="col">Upload CV </th>
              <th scope="col">Candidate Nationality</th>
              <th scope="col">Candidate Location</th>
              <th></th>
            </tr>
          </thead>
          <tbody formArrayName="candidates">
            <tr>
              <td class="text-center">1.</td>
              <td>
                <input type="file" class="form-control" (change)="fileUpload($event)" required>
              </td>
              <td><input type="text" placeholder="Enter nationality" class="form-control"
                  formControlName="nationality" />
              </td>
              <td><input type="text" placeholder="Enter job title" class="form-control" formControlName="location" />
              </td>
              <td>
                <button type="button" class="btn btn-danger" (click)="removeCandidate()">-</button>&nbsp;
                <button type="button" class="btn btn-primary" (click)="addCandidate()">+</button>
              </td>
            </tr>

            <tr>
              <td class="text-center">2.</td>
              <td>
                <input type="file" class="form-control" (change)="fileUpload($event)" required>
              </td>
              <td><input type="text" placeholder="Enter nationality" class="form-control"
                  formControlName="nationality" />
              </td>
              <td><input type="text" placeholder="Enter job title" class="form-control" formControlName="location" />
              </td>
              <td>
                <button type="button" class="btn btn-danger" (click)="removeCandidate()">-</button>&nbsp;
                <button type="button" class="btn btn-primary" (click)="addCandidate()">+</button>
              </td>
            </tr>

            <tr>
              <td class="text-center">3.</td>
              <td>
                <input type="file" class="form-control" (change)="fileUpload($event)" required>
              </td>
              <td><input type="text" placeholder="Enter nationality" class="form-control"
                  formControlName="nationality" />
              </td>
              <td><input type="text" placeholder="Enter job title" class="form-control" formControlName="location" />
              </td>
              <td>
                <button type="button" class="btn btn-danger" (click)="removeCandidate()">-</button>&nbsp;
                <button type="button" class="btn btn-primary" (click)="addCandidate()">+</button>
              </td>
            </tr>

            <tr>
              <td class="text-center">4.</td>
              <td>
                <input type="file" class="form-control" (change)="fileUpload($event)" required>
              </td>
              <td><input type="text" placeholder="Enter nationality" class="form-control"
                  formControlName="nationality" />
              </td>
              <td><input type="text" placeholder="Enter job title" class="form-control" formControlName="location" />
              </td>
              <td>
                <button type="button" class="btn btn-danger" (click)="removeCandidate()"
                  *ngIf="candidates.length > 1">-</button>&nbsp;
                <button type="button" class="btn btn-primary" (click)="addCandidate()">+</button>
              </td>
            </tr>

          </tbody>
        </table>

        <div class="row">
          <div class="col-md-5">
            <label>Timer:</label>
          </div>
          <div class="col-md-7" style="color: red;">
            <div class="timer" id="timer"><strong>24:00:00</strong></div>
          </div>
        </div>

        <div class="col-3" style="float: right;">
          <div class="d-flex align-items-center justify-content-between">
            <button type="button" routerLink="/acr/acr-all-jobs" class="btn btn-primary w-50">Back</button>
            <button type="button" (click)="submit()" class="btn btn-primary w-50">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>