<section class="section-registration d-flex align-items-center justify-content-center" style="min-height: 100vh;">
  <div class="container">
    <div class="FlatCard shadow border-radius24">
      <div class="row">
        <div class="col-lg-5 col-xl-5 col-12 d-flex align-items-center">
          <div class="FormLeft-panel">
            <div class="text-center">
              <img src="../../../../assets/img/Picture1.png" width="200" class="mb-5" />
            </div>
            <h1 class="HeadingLarge">“Elevate Your Career with SaiVen: Join Public Sector IT Projects”</h1>
            <p class="mb-5 textfont">SaiVen, is an emerging IT QA lead business with 40+ business collaborations
              across UK and India. We are a dedicated business to lead the governance of all public sector QA projects
              signed by the group and we are the driving force for client relationship management, project governance,
              and people/delivery management.<br><br>
              Our job is to ensure seamless execution for exceptional results, through bringing our industry expertise
              added with right skills at right time - enabling quick and cost-effective resource augmentation, for
              benefit of our clients.<br><br>Partner with us for exciting contract opportunities and be a part of our
              success
              story</p>
            <p><strong>SaiVen Technology Solutions Limited</strong><br>
              1-04, Focal Point, <br>27-35 Fleet Street, <br>Swindon SN1 1RQ
            </p>
            <p>Company Registration Number: 8856876</p>
            <p>Email: Info@saivensolutions.co.uk</p>
          </div>
        </div>
        <div class="col-lg-7 col-xl-7 col-12 d-flex align-items-center">
          <div class="FormRight-panel w-100">
            <div class="col-12">
              <div class="login-modal">
                <div class="login-body">
                  <div class="modal-body text-center">
                    <img src="assets/img/success-icon.gif" width="100" class="mt-4 mb-4" />
                    <h5 class="mb-5">
                      <strong>Thank you for registering with us, our team will get back to you soon.</strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>