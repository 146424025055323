<section class="section-card">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-7">
        <div class="FlatCard shadow border-radius6 p-0">
          <div class="card-body text-center p-3">
            <img src="./assets/img/success-icon.gif" width="100" class="mt-5 mb-5" />
            <h5 class="mb-5">
              <strong>We have sent a registration link to your referral via email. Please share the assigned Referral
                code with your referral to ensure you receive the referral benefits
              </strong>
            </h5>
            <h4 class="text-muted">
              Your Referral Code :<span style="font-weight: bold;"> {{referredBy}}</span>
            </h4><br>
            <div>
              <button type="button" class="btn btn-secondary w-40" routerLink="/cir/cir-refer-earn">Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>