<section class="section-accordian-card">
  <div class="container">
    <h4 style="text-align: center;"><strong>All Jobs</strong></h4><br>
    <div class="alert alert-warning" role="alert">
      <i class="bi bi-exclamation-triangle"></i><strong> It is important for you to read <span style="color: #34b5b8 !important;">Read me</span> document before you take action.</strong>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="text-transform: none; text-align: center;">Job ID</th>
            <th style="text-transform: none; text-align: center;" scope="col">Job Title</th>
            <th style="text-transform: none; text-align: center;" scope="col">No. of Roles</th>
            <th style="text-transform: none; text-align: center;" scope="col">Publish Date</th>
            <th style="text-transform: none; text-align: center;" scope="col">Day Rate</th>
            <th style="text-transform: none; text-align: center;" [width]="100" scope="col">Role Details</th>
            <th style="text-transform: none; text-align: center;" scope="col">Timer</th>
            <th style="text-transform: none; text-align: center;" [width]="200" scope="col">Take Action</th>
            <th style="text-transform: none; text-align: center;" scope="col">Status</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td *ngIf="joblist.length == 0" class="text-center" colspan="10">
              No Record Found.
            </td>
          </tr>

          <tr
            *ngFor="let job of joblist  |  paginate: { itemsPerPage: pagesize , currentPage: page, totalItems: totalRecords } ; let i = index">
            <td>{{job.job_id}}</td>
            <td>{{job.job_title}}</td>
            <td>{{job.no_of_roles}}</td>
            <td>{{job.publish_date | date:'dd/MM/yyyy'}}</td>
            <td>{{job.day_rate}}</td>
            <td>
              <a href="https://f005.backblazeb2.com/file/whyqtech1/files/1725192312622_READ ME.docx" target="_blank">
                Read me
              </a>
            </td>
            <td style="color: red;">{{ formatTimeLeft(job.job_time_left) }}</td>
            <td class="text-center">
              <ng-container *ngIf="job.status !== 'Under Review'; else emailMessage">
                <div *ngIf="!job.applied">
                  <label
                    [ngClass]="{'enabled-text': !(job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'), 'disabled-text': job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'}">
                    <input type="radio" name="choice{{ i }}" value="yes" (click)="openModal(job)"
                      [disabled]="job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'" />
                    Yes
                  </label>
                  &nbsp;
                  <label
                    [ngClass]="{'enabled-text': !(job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'), 'disabled-text': job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'}">
                    <input type="radio" name="choice{{ i }}" value="no" (click)="noApplyjob(job)"
                      [disabled]="job.status === 'Actioned' || job.status === 'Expired' || job.status === 'Inactive'" />
                    No
                  </label>
                </div>
                <div *ngIf="job.applied == true">
                  <div class="row">
                    <div class="ml-auto mr-auto">
                      <button (click)="openCVModal(job)" type="submit" class="btn btn-primary btn-round">Upload
                        CV</button>
                    </div>
                  </div>
                </div>
              </ng-container>


              <ng-template #emailMessage>
                <span> If you have got more CV's email to : (ayush@westgateithub.com)</span>
              </ng-template>
            </td>
            <td>{{job.status}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-3 align-items-center">
      <div class="row">
        <div class="pagination">
          <pagination-controls (pageChange)="paginate($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template class="modal" #loginDetailModal id="loginDetailModal" tabindex="-1" aria-labelledby="ViewLogin"
  aria-hidden="true">
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewLogin">Upload Details</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="resourcesForm">

          <div class="row">
            <div class="col-md-4">
              <label>How many resources will you be able to provide within 24 hours? <span style="color: red;"> *</span>
                :</label>
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Enter resources"
                  formControlName="howmanyresources">
              </div>
            </div>
          </div>
          <div class="col-3" style="float: right;">
            <div class="d-flex align-items-center justify-content-between">
              <button type="button" [disabled]="!resourcesForm.controls['howmanyresources'].value"
                (click)="submitResources()" class="btn btn-primary w-50">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template class="modal fade" #uploadcvModal id="uploadcvModal" tabindex="-1" aria-labelledby="ViewCv"
  aria-hidden="true">
  <div class="">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewCv">Upload CV</h5>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="resourcesForm">
          <table class="table table-bordered">
            <thead class="card-header">
              <tr>
                <th scope="col">Sr. No</th>
                <th scope="col">Upload CV <span style="color: red;">*</span></th>
                <th scope="col">Candidate Nationality <span style="color: red;">*</span></th>
                <th scope="col">Candidate Location <span style="color: red;">*</span></th>
              </tr>
            </thead>
            <tbody formArrayName="candidates">
              <!-- Add [formGroupName]="i" to bind each row correctly -->
              <tr *ngFor="let candidate of candidates.controls; let i = index" [formGroupName]="i">
                <td class="text-center">{{ i + 1 }}.</td>
                <td>
                  <input type="file" class="form-control" (change)="fileUpload($event, i); onChangeInput()" required>
                </td>
                <td>
                  <input type="text" placeholder="Enter nationality" class="form-control"
                    formControlName="candidate_nationality" (keypress)="onChangeInput()"/>
                </td>
                <td>
                  <input type="text" placeholder="Enter job title" class="form-control"
                    formControlName="candidate_location" (keypress)="onChangeInput()"/>
                </td>
                <!-- <td>
                  <button type="button" class="btn btn-danger" (click)="removeCandidate(i)"
                    *ngIf="candidates.length > 1">-</button>&nbsp;
                  <button type="button" class="btn btn-primary" (click)="addCandidate(i)"
                    *ngIf="i === candidates.length - 1">+</button>
                </td> -->
              </tr>
            </tbody>
          </table>

          <div class="col-3" style="float: right;">
            <div class="d-flex align-items-center justify-content-between">
              <button type="button" [disabled]="errorData" (click)="submitCV()" class="btn btn-primary w-50" [ngClass]="">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
