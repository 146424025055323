<section class="section-accordian-card">
  <div class="container">
    <h4 style="text-align: center;"><strong>Future Roles</strong></h4>
    <p class=" mb-4 text-center text-bold">
      Please select a contract from the options below to view available Positions.<br>
      Mandatory : Select atleast 1 option in each Contract to move to next page
    </p>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
            Contract 1 - Location Manchester / Swindon / India
          </button>
        </h2>
        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="text-center mb-4 text-bold">
              Please select the Job Titles you're interested in from the options below for each contract. <br>You may
              choose multiple job titles .
            </p>
            <div class="table-responsive">
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th scope="col">Select</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of manchesterrolesTableData; let i = index">
                    <td>
                      <input type="checkbox" [checked]="selected(role.role)" class="form-check-input chckbox ms-3"
                        [(ngModel)]="role.selected" id="checkbox{{ i + 1 }}">
                    </td>
                    <td>
                      <label class="form-check-label" for="checkbox{{ i + 1 }}" [innerHTML]="role.role"></label>
                    </td>
                    <td>
                      <a ngbTooltip="{{ role?.description }}">
                        <span>
                          {{ (role?.description ?? '').length > 200
                          ? (role?.description | slice: 0:200) + '... '
                          : role?.description }}
                          <span *ngIf="(role?.description ?? '').length > 200" style="color: #34b5b8 ;">Read more</span>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            Contract 2 - Northern Ireland / Swindon / India
          </button>
        </h2>
        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="text-center mb-4 text-bold">
              Please select the Job Titles you're interested in from the options below for each contract. <br>You may
              choose multiple job titles .
            </p>
            <div class="table-responsive">
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th scope="col">Select</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of northanRolesTableData; let i = index">
                    <td>
                      <input type="checkbox" [checked]="selected(role.role)" class="form-check-input ms-3 chckbox"
                        [(ngModel)]="role.selected" id="checkbox{{ i + 1 }}">
                    </td>
                    <td>
                      <label class="form-check-label" for="checkbox{{ i + 1 }}" [innerHTML]="role.role"></label>
                    </td>
                    <td>
                      <a ngbTooltip="{{ role?.description }}">
                        <span>
                          {{ (role?.description ?? '').length > 200
                          ? (role?.description | slice: 0:200) + '... '
                          : role?.description }}
                          <span *ngIf="(role?.description ?? '').length > 200" style="color: #34b5b8 ;">Read more</span>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Contract 3 Public Sector Pool - Location : All over UK / Swindon / India
          </button>
        </h2>
        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
          data-bs-parent="#accordionFlushExample">
          <div class="accordion-body">
            <p class="text-center mb-4 text-bold">
              Please select the Job Titles you're interested in from the options below for each contract. <br>You may
              choose multiple job titles .
            </p>
            <div class="table-responsive">
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th scope="col">Select</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let service of qaSpecialistServices; let i = index">
                    <td>
                      <input type="checkbox" [checked]="selected(service.role)" class="form-check-input ms-3 chckbox"
                        [(ngModel)]="service.selected" id="checkbox{{ i + 1 }}">
                    </td>
                    <td>
                      <label class="form-check-label" for="checkbox{{ i + 1 }}" [innerHTML]="service.role"></label>
                    </td>
                    <td>
                      <a ngbTooltip="{{ service?.description }}">
                        <span>
                          {{ (service?.description ?? '').length > 200
                          ? (service?.description | slice: 0:200) + '... '
                          : service?.description }}
                          <span *ngIf="(service?.description ?? '').length > 200" style="color: #34b5b8 ;">Read
                            more</span>
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="update ml-auto mr-auto">
        <button type="button" style="float: left;" routerLink="/cir/cir-register"
          class="btn btn-primary btn-round">Back</button>
        <button type="button" style="float: right;" class="btn btn-primary btn-round" (click)="submit()">Next
          Step</button>
      </div>
    </div>
  </div>
</section>