<div class="section-card">
  <div class="FlatCard shadow border-radius6 m-3">
    <div class="card-header">
      <h3 class="card-title">DATABASE Table List</h3>
    </div>
    <div class="card-body p-3">
      <div>
        <table class="table">
          <thead class="text-black">
            <th style="text-align: center;">Table Name</th>
            <th style="text-align: center;">Action</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of tableData">
              <tr>
                <td style="text-align: center;">{{ item?.tableName }}</td>
                <td style="text-align: center;">
                  <button class="btn btn-sm btn-primary" (click)="redirectToDetailPage(item?.type)">View Details</button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
